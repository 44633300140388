import React from 'react'
import Company from '../../../components/company/Company'
import { companyName, logos } from '../../../data/data'
import bioghar1 from '../../../assets/images/BioGhar/bioghar1.webp'
import bioghar2 from '../../../assets/images/BioGhar/bioghar2.webp'
import bioghar3 from '../../../assets/images/BioGhar/bioghar3.webp'
import bioghar4 from '../../../assets/images/BioGhar/bioghar4.webp'

import { useRecoilState } from 'recoil'
import IndexTranslate from '../../../atoms/translateIndex'

export const bioGharImages=[bioghar1,bioghar2,bioghar3,bioghar4]
const BioGhar = () => {

let newImages=bioGharImages.map(image =>{
    return {
      original: image,
      thumbnail: image,
      originalHeight: "300px",
    }
  })
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="section-top">
      <Company
        companyName={companyName[2][indexTranslate]}
        companyLogo={logos[2]}
        images={newImages}
      />
    </div>
  )
}

export default BioGhar
