import { Col, Container, Row } from "react-bootstrap";
import Frame from "../../components/utility/frame/Frame";
import "./logos.css";
import logo1 from "../../assets/images/logos/logo1.webp";
import logo2 from "../../assets/images/logos/logo2.webp";
import logo3 from "../../assets/images/logos/logo3.webp";
import logo4 from "../../assets/images/logos/logo4.webp";
import logo5 from "../../assets/images/logos/logo5.webp";
import logo6 from "../../assets/images/logos/logo6.webp";
import logo7 from "../../assets/images/logos/logo7.webp";
import logo8 from "../../assets/images/logos/logo8.webp";
import logo9 from "../../assets/images/logos/logo9.webp";
import logo10 from "../../assets/images/logos/logo10.webp";
import logo11 from "../../assets/images/logos/logo11.webp";
import logo12 from "../../assets/images/logos/logo12.webp";
import logo13 from "../../assets/images/logos/logo13.webp";
import logo14 from "../../assets/images/logos/logo14.webp";
import logo15 from "../../assets/images/logos/logo15.webp";
import logo16 from "../../assets/images/logos/logo16.webp";
import logo17 from "../../assets/images/logo1.webp";
import logo18 from "../../assets/images/logo2.webp";
import logo19 from "../../assets/images/logo4.webp";
import { useRecoilState } from "recoil";
import IndexTranslate from "../../atoms/translateIndex";
import { logos } from "../../data/data";
import { motion } from "framer-motion";
const images = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  ...logos.slice(0, 7),
];
const Logos = () => {
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="logos-page">
      {indexTranslate === 0 ? (
        <Frame title="Logos" />
      ) : (
        <Frame title=" شعارات" />
      )}

      <Container>
        <Row>
          {images.map((img) => {
            return (
              <Col md="3" className="logos-image" key={img}>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 ,scale: 1.1 }}
                  viewport={{ once: false }}
                  transition={{ duration: 2 }}
                >
                  <img src={img} alt=""  loading="lazy"/>
                </motion.div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Logos;
