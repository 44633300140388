import React from 'react'
import Company from '../../../components/company/Company'
import { companyName, logos } from '../../../data/data'
import sham1 from '../../../assets/images/sham/sham1.jfif'
import sham2 from '../../../assets/images/sham/sham2.jfif'

import { useRecoilState } from 'recoil'
import IndexTranslate from '../../../atoms/translateIndex'

export const shamImages=[sham1,sham2]
const ShamMovers = () => {
  let newImages=shamImages.map(image =>{
    return {
      original: image,
      thumbnail: image,
      originalHeight: "300px",
    }
  })
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="section-top">
    <Company
      companyName={companyName[3][indexTranslate]}
      companyLogo={logos[3]}
      images={newImages}
    />
  </div>
  )
}

export default ShamMovers
