import Company from '../../../components/company/Company'
import { companyName, logos } from '../../../data/data'
import biggest1 from '../../../assets/images/biggestCoin/biggest1.webp'
import biggest2 from '../../../assets/images/biggestCoin/biggest2.webp'
import biggest3 from '../../../assets/images/biggestCoin/biggest3.webp'
import biggest4 from '../../../assets/images/biggestCoin/biggest4.webp'
import biggest5 from '../../../assets/images/biggestCoin/biggest5.webp'
import biggest6 from '../../../assets/images/biggestCoin/biggest6.webp'
import biggest7 from '../../../assets/images/biggestCoin/biggest7.webp'
import biggest8 from '../../../assets/images/biggestCoin/biggest8.webp'
import { useRecoilState } from 'recoil'
import IndexTranslate from '../../../atoms/translateIndex'

export const biggestCoinImages=[
  biggest1,
  biggest2,
  biggest3,
  biggest4,
  biggest5,
  biggest6,
  biggest7,
  biggest8,
]
const BiggestCoin = () => {

  const newImages =biggestCoinImages.map(image =>{
    return {
      original: image,
      thumbnail: image,
      originalHeight: "300px",
    }
  })
      const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="section-top">
    <Company
      companyName={companyName[7][indexTranslate]}
      companyLogo={logos[7]}
      images={newImages}
    />
  </div>
  )
}

export default BiggestCoin
