import CountUp from "react-countup";
import "./count.css";
import { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import IndexTranslate from "../../atoms/translateIndex";
import { useRecoilState } from "recoil";
const CountSection = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <ScrollTrigger
      onEnter={() => {
        setCounterOn(true);
      }}
      onExit={() => {
        setCounterOn(false);
      }}
    >
      {indexTranslate === 0 ? (
        <div className="container py-5 my-5">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="counter">
                <div className="counter-icon">
                  <i className="fa fa-globe"></i>
                </div>
                <h3>Completed Projects</h3>
                <span className="counter-value">
                  {counterOn && <CountUp start={0} end={200}></CountUp>}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter orange">
                <div className="counter-icon">
                  <i className="fa fa-rocket"></i>
                </div>
                <h3>Web Development</h3>
                <span className="counter-value">
                  {counterOn && <CountUp start={0} end={100}></CountUp>}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter blue">
                <div className="counter-icon">
                  <i className="fa fa-rocket"></i>
                </div>
                <h3> Brand Building</h3>
                <span className="counter-value">
                  {counterOn && <CountUp start={0} end={125}></CountUp>}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter green">
                <div className="counter-icon">
                  <i className="fa fa-rocket"></i>
                </div>
                <h3>Our Customers</h3>
                <span className="counter-value">
                  {counterOn && <CountUp start={0} end={120}></CountUp>}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container py-5 my-5" style={{direction:'ltr'}}>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="counter">
                <div className="counter-icon">
                  <i className="fa fa-globe"></i>
                </div>
                <h3>مشاريع منجزة</h3>
                <span className="counter-value">
                  {counterOn && <CountUp start={0} end={200}></CountUp>}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter orange">
                <div className="counter-icon">
                  <i className="fa fa-rocket"></i>
                </div>
                <h3>تطوير المواقع</h3>
                <span className="counter-value">
                  {counterOn && <CountUp start={0} end={100}></CountUp>}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter blue">
                <div className="counter-icon">
                  <i className="fa fa-rocket"></i>
                </div>
                <h3> بناء الهوية البصرية</h3>
                <span className="counter-value">
                  {counterOn && <CountUp start={0} end={125}></CountUp>}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter green">
                <div className="counter-icon">
                  <i className="fa fa-rocket"></i>
                </div>
                <h3>عملائنا</h3>
                <span className="counter-value">
                  {counterOn && <CountUp start={0} end={120}></CountUp>}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </ScrollTrigger>
  );
};

export default CountSection;
