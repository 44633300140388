import './notfound.css'
const NotFound = () => {
  return (
    <div className='page-not-found'>
     
    </div>
  )
}

export default NotFound
