import React from 'react'
import Company from '../../../components/company/Company'
import { companyName, logos } from '../../../data/data'
import windex1 from '../../../assets/images/windex/windex1.jfif'
import windex2 from '../../../assets/images/windex/windex2.jfif'
import windex3 from '../../../assets/images/windex/windex3.jfif'
import { useRecoilState } from 'recoil'
import IndexTranslate from '../../../atoms/translateIndex'
export const windexImages=[windex1,windex2,windex3]
const Windex = () => {
  const newImages =windexImages.map(image =>{
    return {
      original: image,
      thumbnail: image,
      originalHeight: "300px",
    }
  })
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="section-top">
    <Company
      companyName={companyName[6][indexTranslate]}
      companyLogo={logos[6]}
      images={newImages}
    />
  </div>
  )
}

export default Windex