import { Button, Card } from "react-bootstrap";
import "./card.css";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import { Link } from "react-router-dom";
import useGoToTop from "../../hook/useGoToTop";
import { motion } from "framer-motion";

const PortfolioCard = ({ image, company, link }) => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const { view } = { ...translate.portfolio };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: false }}
      transition={{ duration:2 }}

    >
      <Card style={{ width: "18rem" }} className="portfolio-card wow fadeInUp">
        <div className="portfolio-card-image">
          <Card.Img variant="top" src={image} loading="lazy" />
        </div>
        <Card.Body>
          <Card.Title>{company}</Card.Title>
          <Link to={link} onClick={useGoToTop}>
            <Button>{view[indexTranslate]}</Button>
          </Link>
        </Card.Body>
      </Card>
    </motion.div>
  );
};

export default PortfolioCard;
