import logo1 from "../assets/images/logo1.webp";
import logo2 from "../assets/images/logo2.webp";
import audaz from "../assets/images/logo3.webp";
import logo4 from "../assets/images/logo4.webp";
import bioghar from "../assets/images/logo5.webp";
import shammovers from "../assets/images/logo6.webp";
import qasralrand from "../assets/images/logo7.webp";
import kenz from "../assets/images/logo8.webp";
import windex from "../assets/images/logo9.webp";
import biggestcoin from "../assets/images/logo10.webp";
import logo from "../assets/images/logos.jfif";
import roya from "../assets/images/logos/شعارات العملاء-01.png";
import logo_first_gulf from "../assets/images/logos/شعارات العملاء-04.png";
import logo_for_you_cleaning from "../assets/images/logos/شعارات العملاء-07.png";
import logo_jannah_net from "../assets/images/logos/شعارات العملاء-06.png";
import webdevelpment from "../assets/images/webdevelpment.webp";
import web1 from "../assets/images/webdevelopment/biggestcoin.jpg";
import web2 from "../assets/images/webdevelopment/shammovers.png";
import web3 from "../assets/images/webdevelopment/hkayat.png";
import web4 from "../assets/images/webdevelopment/jananet.png";
import web5 from "../assets/images/webdevelopment/bioghar.png";
import web6 from "../assets/images/webdevelopment/dizifood.png";
import web7 from "../assets/images/webdevelopment/firstgulf.png";
import web8 from "../assets/images/webdevelopment/oil.png";
import web9 from "../assets/images/webdevelopment/reyceclewebsite.png";
import web10 from "../assets/images/webdevelopment/foryouwebsite.png";
import web11 from "../assets/images/webdevelopment/nooralhodajewelry.webp";
import web12 from "../assets/images/webdevelopment/tawdeih.png";
import web13 from "../assets/images/webdevelopment/wattage.png";
import web14 from "../assets/images/webdevelopment/glasium.png";
import web15 from "../assets/images/webdevelopment/fourmasters.png";
import web16 from "../assets/images/webdevelopment/elegancemarble.png";
import web17 from "../assets/images/webdevelopment/fixahome.png";
import web18 from "../assets/images/webdevelopment/abdulrahim-transport.png";


export const logos = [
  webdevelpment,
  audaz,
  bioghar,
  shammovers,
  qasralrand,
  kenz,
  windex,
  biggestcoin,
  logo_jannah_net,
  roya,
  logo_first_gulf,
  logo_for_you_cleaning,

];

export const companyName = [
  ["Web Development", "تطوير المواقع الالكترونية"],
  ["Audaz Properties", "اوداز العقارية"],
  ["BioGhar", "بيوغار"],
  ["Sham Movers", "شام للنقليات"],
  ["Qasr Alrand", "قصر الرند"],
  ["Kenz", "كينز"],
  ["Car Wash windex", "ويندكس"],
  ["the Biggest Coin", "اكبر عملة نقدية بالعالم"],
  ["Logos", "شعارات"],
  ["Advertising Posts", "منشورات إعلانية"],
  ["Jannah Net", "Jannah Net"],
  ["Roya", "Roya"],
  ["First Gulf", "First Gulf"],
  ["foryoucleaning", "foryoucleaning"],


];

export const links = [
  "/portfolio/websites",
  "/company/audaz",
  "/company/bioghar",
  "/company/ShamMovers",
  "/company/Qasr-Alrand",
  "/company/Kenz",
  "/company/Windex",
  "/company/biggest-coin",
  "/company/Logos",
  "/company/AdvertisingPosts",
  "/company/Publication",
  "/portfolio/websites",
  "/portfolio/websites",
  "/portfolio/websites",
  "/portfolio/websites",

];

export let websitesWork = [
  {
    id: "the biggest coin",
    image: web1,
    name: "The Biggest Coin",
    description:["The largest painting of Sheikh Zayed Al Nahyan 'Mercy of God on him' in coins of the countries of the world The Emirate of Dubai","أكبر لوحة للشيخ زايد آل نهيان رحمه الله بالعملات المعدنية لدول العالم إمارة دبي"],
    link: "https://thebiggestcoin.com",
  },
  {
    id: "sham movers",
    image: web2,
    name: "Sham Movers",
    description:[ "Dubai Packaging And Movers Company Limited, Syrian Arabic Company","شركة عربية سورية في دبي تعمل في مجال النقل والتغليف"]
     ,
    link: "https://shammovers.net",
  },
  {
    id: "Hkayat Resturent",
    image: web3,
    name: "Hkayat Resturent",
    description:[ "Specialty Coffee Shop and Levant food kitchen, where we combine the best of both worlds to offer you a unique culinary experience"," مطعم يقدم قهوة مميزة بالإضافة لمأكولات شامية "]
     ,
    link: "https://hkayat.net",
  },
  {
    id: "janna net",
    image: web4,
    name: "Janna Net Online Store",
    description: ["janna net,the best online shopping experience","متجر الكتروني لبيع المنتجات"],
    link: "https://jannanet.com/",
  },
  {
    id: "bioghar",
    image: web5,
    name: "Bioghar",
    description:[ "Bioghar company was established in Dubai launching from Syria to gather the natural components to put them into your hands.","متجر الكتروني متخصص في بيع مستحضرات العناية بالبشرة"]
     ,
    link: "https://bioghar.ae",
  },
  {
    id: "dizifood",
    image: web6,
    name: "dizifood",
    description:[ "restaurant aims to serve you the most delicious and delightful dish","  مطعم سحابي يقدم  أشهى وأطيب الأطباق"]
     ,
    link: "https://dizifood.net/",
  },
  {
    id: "first gulf",
    image: web7,
    name: "First Gulf Company",
    description:[ "Gulf First Trading Company is a factory for producing chocolates and cakes in Dhofar Governorate, Sultanate of Oman.","  شركة الخليج الأول تعمل في مجال صناعة الشوكولا الفاخرة بالإضافة الى الكيك"]
     ,
    link: "https://firstgulf-llc.net/",
  },
  {
    id: "Almusafir International",
    image: web8,
    name: "Almusafir International",
    description:[ "leading company in the field of selling high-quality oils, tires, and car batteries.","  شركة رائدة في مجال بيع زيوت السيارات والإطارات والبطاريات"]
     ,
    link: "https://musafirtiresoil.com/",
  },
  {
    id: "Recycle alzaabi",
    image: web9,
    name: "Recycle Ali Alzaabi",
    description:[ "A volunteer in United Arab Emirates in many volunteer fields ","متطوع على مستوى دولة الإمارات العربية المتحدة في العديد من المجالات التطوعية  "]
     ,
    link: "https://recyclealzaabi.com/",
  },
  {
    id: "For you Cleaning company",
    image: web10,
    name: "For you Cleaning company",
    description:[ "Cleaning company specializing in providing professional cleaning services","  شركة تنظيف متخصصة في تقديم خدمات التنظيف الاحترافية للعملاء "]
     ,
    link: "https://foryoucleaning.com/",
  },
  {
    id: " Noor Al Huda jewelry",
    image: web11,
    name: "Noor Al Huda jewelry",
    description:[ "Noor Alhoda jewelry , that was established in 2019. We take pride in providing you with a unique and exceptional shopping experience in the world of gold jewelry.","  تأسست مجوهرات نور الهدى في عام 2019. نحن نفخر بتقديم تجربة تسوق فريدة واستثنائية في عالم المجوهرات الذهبية.         "]
     ,
    link: "https://nooralhodajewel.com/",
  },
  {
    id: "Tawdeih",
    image: web12,
    name: "Tawdeih",
    description:[ "Tawdeih, that was established in 2021, specialized in services like air conditioners works, home appliances, restaurant equipment, electrical works, plumping.",
    "شركة توضيح تأسست عام 2021 متخصصة في خدمات مثل أعمال المكيفات، الأجهزة المنزلية، معدات المطاعم، الأعمال الكهربائية، السباكة."]
     ,
    link: "https://tawdeih.com/",
  },
  {
    id: "Wattagemep",
    image: web13,
    name: "Wattagemep",
    description:[ "Wattage Electromechanical LLC, established in 2023, its a leading MEP Mechanical, Electrical and Plumbing service provider in the UAE.",
     "تأسست شركة وتاج في عام 2023، وهي شركة رائدة في مجال الاعمال الكهربائية والميكانيكية مزود الخدمة (الميكانيكية والكهربائية والسباكة في دولة الإمارات العربية المتحدة."]
     ,
    link: "https://wattagemep.com/",
  },
  {
    id: "Glasium",
    image: web14,
    name: "Glasium",
    description:[ "Glasium specializes in aluminium and glass. We produce incredible quality products that defy the weather conditions, have superb thermal insulation properties.",
     "شركة جلاسيوم متخصصة في صناعة الألمنيوم والزجاج. نحن ننتج منتجات ذات جودة مذهلة تتحدى الظروف الجوية، وتتمتع بخصائص عزل حراري رائعة، وتضفي الأناقة على أي مشهد."]
     ,
    link: "https://glasium.net/",
  },
  {
    id: "Fourmasters",
    image: web15,
    name: "Fourmasters",
    description:[ "Our company works in the field of general trade and specializes in providing a variety of products and services to our customers.",
     " شركة فورماسترز متخصصة في مجال التجارة العامة وتتخصص في تقديم مجموعة متنوعة من المنتجات والخدمات لعملائنا."]
     ,
    link: "https://fourmasters.ae/",
  },
  {
    id: "Elegancemarble Online Store",
    image: web16,
    name: "Elegancemarble Online Store",
    description:[ "Elegancemarble store for trading marble, granite and natural stones.",
     "متجر Elegancemarble لتجارة الرخام والغرانيت والاحجار الطبيعية "]
     ,
    link: "https://elegancemarble.net/",
  },
  {
    id: "FixaHomeDubai",
    image: web17,
    name: "FixaHomeDubai",
    description:["Fixa Home Dubai boasts over +10 years of expertise in repairing household appliances.",
     "تتمتع شركة Fixa Home Dubai بخبرة تزيد عن 10 سنوات في إصلاح الأجهزة المنزلية."]
     ,
    link: "https://fixahomedubai.com",
  },
  {
    id: "Mohammad Abdulrahim Transport",
    image: web18,
    name: "Mohammad Abdulrahim Transport",
    description:["Mohamed Abdel Rahim Company for Gravel and Sand transporting and delivering construction and heavy materials.",
     "شركة محمد عبد الرحيم لنقل الحصى والرمل ونقل وتوصيل المواد الانشائية والثقيلة."]
     ,
    link: "https://abdulrahim-transport.com/",
  },
];
