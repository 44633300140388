import "./location.css";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import Frame from "../utility/frame/Frame";
const Location = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="map mt-5">
      <div className="container">
        <div className="section-title text-center">
        <Frame title={translate.location.title[indexTranslate]}/>
        </div>
        <div className="row">
          <div className="map-container col-lg-6 wow animate__backInLeft">
            <h2 className="title-map">
              {translate.nav.TakfilDocumentsClearingServices[indexTranslate]}
            </h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7216.644894117039!2d55.313799253906225!3d25.259736299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d7eb6bc7afb%3A0x7b8e723be3508a44!2z2KrZg9mB2YrZhCDZhNiu2K_Zhdin2Kog2YXYqtin2KjYudipINin2YTZhdi52KfZhdmE2KfYqiBUYWtmaWwgRG9jdW1lbnRzIENsZWFyaW5n!5e0!3m2!1sen!2sae!4v1676639803050!5m2!1sen!2sae"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              style={{ border: 0 }}
              title="map"
            ></iframe>
          </div>
          <div className="map-container mt-xs-20 col-lg-6 wow animate__backInRight">
            <h2 className="title-map">
              {translate.nav.TakfilDigitalMarketing[indexTranslate]}
            </h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14433.289788234079!2d55.322039!3d25.2597363!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x17afa439d01574dc!2sTakfil%20Digital%20marketing!5e0!3m2!1sen!2sae!4v1676886832397!5m2!1sen!2sae"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="map-2"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
