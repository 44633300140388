import { Container, Row, Col } from "react-bootstrap";
import "./about.css";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import about from "../../assets/images/servicesLogo.webp";
import digital from "../../assets/images/marketingLogo.webp";
import Header from "../../components/utility/header/Header";
import { Helmet } from "react-helmet-async";
const AboutUs = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const { title1, des1, title2, des2 } = { ...translate.aboutUs };
  return (
    <>
      {indexTranslate === 0 ? (
        <Helmet>
          <meta
            name="description"
            content="Leading digital marketing & document clearing agency. Expertise in government & private transactions, company establishment, VAT, trademark registration, and more."
          />

          <title>Takfil | AboutUs | Empowering Businesses | Expert Digital Marketing Solutions</title>
        </Helmet>
      ) : (
        <Helmet>
          <meta
            name="description"
            content="نقدم كافة الإستشارات الإستثمارية في جميع المجالات الإقتصادية، من
        خلال أفضل الكوادر المختصة في المجال الإستثماري في دبي. -إنهاء إجراءات
        التأسيس دون أن يضطر رجل الأعمال أن يقوم بأي إجراء ، وهذا من خلال أفضل
        المتخصصين في تخليص كافة الإجراءات، التي يمكن أن تحتاجها أي شركة استثمارية
        لبدء نشاطها التجاري في أسرع وقت وبجودة عالية. "
          />
          <meta
            name="description"
            content="لكي تزيد أرباح شركتك أو اي نشاط تجاري تقوم به نسعى للنتائج غير المسبوقة في مجال التسويق الرقمي وبناء علامة تجارية ناجحة توسيع نطاق وجودنا على وسائل التواصل الإجتماعي ومختلف قنوات التسويق هدفنا أيضا تقديم أفضل الحلول الممكنة التي تساعد العميل في الوصول إلى هدفه و كسب ثقته وتقديم له أعلى درجات الجودة وسنساعده في الدخول إلى أسواق جديدة وكسب عملاء جدد وتحقيق عائدات للإستثمار من خلال مجموعة من الاستراتيجيات إستراتيجية إنشاء متجر إلكتروني أو موقع ويب استراتيجية التسويق عبر محركات البحث استراتيجية التواصل عبر مواقع التواصل الإجتماعي استراتيجية التسويق عبر البريد الإلكتروني استراتيجية التسويق عن طريق الإعلانات المدفوعة استراتيجية التسويق بالمحتوى(الكتابي، المرئي ،المسموع) "
          />

          <title> تكفيل | حول الشركة </title>
        </Helmet>
      )}
      {indexTranslate === 0 ? (
        <div className="about-us">
          <Header
            link="/aboutUs"
            secondPage={translate.nav.aboutUs[indexTranslate]}
          />
          <div className="about-us-section-1">
            <Container>
              <Row>
                <Col lg="6" className="about-us-content">
                  <h2 className="about-us-title">{title1[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {des1[indexTranslate]}
                  </div>
                </Col>
                <Col lg="6" className="about-us-image">
                  <img src={about} alt="about-icon" loading="lazy" />
                </Col>
              </Row>
            </Container>
          </div>
          <div className="about-us-section-2">
            <Container>
              <Row>
                <Col lg="6" className="about-us-image">
                  <img src={digital} alt="about-icon" loading="lazy" />
                </Col>
                <Col lg="6" className="about-us-content">
                  <h2 className="about-us-title">{title2[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {des2[indexTranslate]}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      ) : (
        <div className="about-us" style={{ direction: "rtl" }}>
          <Header
            link="/aboutUs"
            secondPage={translate.nav.aboutUs[indexTranslate]}
          />
          <div className="about-us-section-1">
            <Container>
              <Row>
                <Col lg="6" className="about-us-content">
                  <h2 className="about-us-title">{title1[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {des1[indexTranslate]}
                  </div>
                </Col>
                <Col lg="6" className="about-us-image">
                  <img src={about} alt="about-icon" loading="lazy"/>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="about-us-section-2">
            <Container>
              <Row>
                <Col lg="6" className="about-us-image">
                  <img src={digital} alt="about-icon" loading="lazy"/>
                </Col>
                <Col lg="6" className="about-us-content">
                  <h2 className="about-us-title">{title2[indexTranslate]}</h2>
                  <div className="about-us-description">
                    {des2[indexTranslate]}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default AboutUs;
