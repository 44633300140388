import "./publications.css";
import Publication1 from "../../assets/images/Publications/Publication1.webp";
import Publication2 from "../../assets/images/Publications/Publication2.webp";
import Publication3 from "../../assets/images/Publications/Publication3.webp";
import Publication4 from "../../assets/images/Publications/Publication4.webp";
import Publication5 from "../../assets/images/Publications/Publication5.webp";
import Publication6 from "../../assets/images/Publications/Publication6.webp";

import { useRecoilState } from "recoil";
import IndexTranslate from "../../atoms/translateIndex";
import Frame from "../../components/utility/frame/Frame";
import { Col, Container, Row } from "react-bootstrap";
import { audazImages } from "../compaines/Audaz/Audaz";
import { shamImages } from "../compaines/sham/ShamMovers";
import { qasrImages } from "../compaines/qasr-alrand/QasrAlrand";
import { bioGharImages } from "../compaines/BioGhar/BioGhar";


let Publication = [
  Publication1,
  Publication2,
  Publication3,
  Publication4,
  Publication5,
  Publication6,
];
const Publications = () => {
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="logos-page">
      {indexTranslate === 0 ? (
        <Frame title="Publication" />
      ) : (
        <Frame title="مطبوعات " />
      )}
      <Container>
        <Row>
          {Publication.map((Publication) => {
            return (
              <Col
                lg="4"
                className="business-image publication"
                key={Publication}
              >
                <div
     
                >
                  <img
                    src={Publication}
                    alt=""
                    key={Publication}
                    style={{ height: "300px" }}
                    loading="lazy"
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Publications;
