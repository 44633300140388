import "./takfilDigital.css";
import { Container, Row, Col } from "react-bootstrap";
import icon1 from "../../assets/images/ecommerce.png";
import icon2 from "../../assets/images/code.webp";
import icon3 from "../../assets/images/digitalIcon.webp";

import ecommerce from "../../assets/images/eco-icon.webp";
import webdevelpment from "../../assets/images/webdevelpment.webp";
import hostIcon from "../../assets/images/host-icon.webp";
import digital from "../../assets/images/digital.webp";
import amazon from "../../assets/images/amazon-icon.webp"
import productListingimage from "../../assets/images/productListing.webp"
import mobiledevelopment from "../../assets/images/mobiledev.webp"
import mantainesdevelopment from "../../assets/images/maintenance.webp"

import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import Header from "../../components/utility/header/Header";
import { Helmet } from "react-helmet-async";

const TakfilDigital = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const {
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    text7,
    text8,
    text9,
    text10,
    DigitalMarketing,
    DigitalMarketingdes,
    productListing
  } = {
    ...translate.takfilDigital,
  };
  return (
    <>
      {indexTranslate === 0 ? (
        <Helmet>
        <meta property="og:site_name" content="Takfil | Digital Marketing Agency" />
          <meta
            name="description"
            content="اقتراح حلول لزيادة العملاء والعائد من الإستثمار واعتماد آخر استراتيجيات والتقنيات لزيادة المبيعات والتفوق على المنافسين في مجال التجارة الإلكترونية وتدريب العملاء على إدارة أشهر المتاجر الإلكترونية منها (Wordpress-opencart)"
          />
          <meta name="keyword" content="" />
          <title>Takfil Digital Marketing </title>
        </Helmet>
      ) : (
        <Helmet>
          <meta
            name="description"
            content="اقتراح حلول لزيادة العملاء والعائد من الإستثمار واعتماد آخر استراتيجيات والتقنيات لزيادة المبيعات والتفوق على المنافسين في مجال التجارة الإلكترونية وتدريب العملاء على إدارة أشهر المتاجر الإلكترونية منها (Wordpress-opencart)"
          />

          <meta
            name="description"
            content="إنشاء وإدارة الحملات الإعلانية على مختلف منصات التواصل الإجتماعي فيسبوك انستغرام واتساب تيكتوك يوتيوب وكذلك إدارة الحملات الإعلانية الممولة على أشهر محركات البحث جوجل ادس وبينج ادس تحسين محتوى المواقع ورفع ترتيبها في نتائج محركات البحث بالإعتماد على أدوات التحليل المتقدمة (sumrush-google analyctis)"
          />
          <meta
            name="keywords"
            content="شركة تصميم مواقع الويب دبي ، شركة تطوير مواقع
الويب دبي ، خدمة تصميم مواقع الويب ، تطوير مواقع الويب ، خدمات تصميم مواقع
الويب دبي, مصممين المواقع الالكترونية في دبي"
          ></meta>
          <meta name="keyword" content="" />
          <title>  تكفيل للتسويق الرقمي </title>
        </Helmet>
      )}
      {indexTranslate === 0 ? (
        <div className="takfil-digital">
          <Header
            link="/takfilDigitalMarketing"
            secondPage={translate.nav.TakfilDigitalMarketing[indexTranslate]}
          />
          <Container>
          <div className="digital-container">
          <Row>
            <Col lg="3">
              <div className="digital-box">
                <img src={icon1} alt="icon-e-commerce" loading="lazy" />
                <h2>
                  {
                    translate.takfilDigital.Ecommercedevelopment[
                      indexTranslate
                    ]
                  }
                </h2>
              </div>
            </Col>
            <Col lg="3">
              <div className="digital-box">
                <img src={icon2} alt="icon-web-design" loading="lazy" />
                <h2>
                  {
                    translate.takfilDigital.Websitedesignanddevelopment[
                      indexTranslate
                    ]
                  }
                </h2>
              </div>
            </Col>
            <Col lg="3">
              <div className="digital-box">
                <img src={icon3} alt="icon-e-commerce" loading="lazy" />
                <h2>{DigitalMarketing[indexTranslate]}</h2>
              </div>
            </Col>
            <Col lg="3">
            <div className="digital-box">
              <img src={productListingimage} alt="icon-e-commerce" loading="lazy" />
              <h2>{productListing[indexTranslate]}</h2>
            </div>
          </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="digital-box-des">
                <p>
                  <img src={ecommerce} alt="icon" loading="lazy" />
                  {text1[indexTranslate]}
                </p>
                <p>
                  <img src={webdevelpment} alt="" loading="lazy" />
                  {text2[indexTranslate]} <br />
                  {text5[indexTranslate]}
                  <br />
                  {text6[indexTranslate]}
                  <br />
                  {text3[indexTranslate]}
                  <br />
                  {text4[indexTranslate]}
                  <br />
                  {text7[indexTranslate]}
                  <br />
                  {text8[indexTranslate]}
                  <br />
                  {text9[indexTranslate]}
                  <br />
                </p>
                <p>
                  <img src={digital} alt="" loading="lazy" />
                  {DigitalMarketingdes[indexTranslate]}
                </p>
                <p>
                <img src={amazon} alt="" loading="lazy" />
                {text10[indexTranslate]}
              </p>
                 <p>
                <img src={mobiledevelopment} alt="" loading="lazy" />
                Using the latest technologies, we help businesses develope mobile applications that run on multiple platforms costs while providing your users with an app that fits their OS. Use this alternative to reduce costs and time-to-market and to reach more users without loss of quality.
              </p>
              <p>
              <img src={mantainesdevelopment} alt="" loading="lazy" />
              We are a go-to source to keep your apps functional, reliable, and relevant to your ever-evolving business needs. Our web application support and maintenance services cover everything from content updates, web programming, bug fixes, code reviews, MYSQL, SQL database support & updates, Mobile Apps (iOS & Android) support, and application hosting support.
            </p>
              </div>
            </Col>
          </Row>
        </div>
          </Container>
        </div>
      ) : (
        <div className="takfil-digital" style={{ direction: "rtl" }}>
          <Header
            link="/takfilDigitalMarketing"
            secondPage={translate.nav.TakfilDigitalMarketing[indexTranslate]}
          />
          <Container>
            <div className="digital-container">
              <Row>
                <Col lg="3">
                  <div className="digital-box">
                    <img src={icon1} alt="icon-e-commerce" loading="lazy" />
                    <h2>
                      {
                        translate.takfilDigital.Ecommercedevelopment[
                          indexTranslate
                        ]
                      }
                    </h2>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="digital-box">
                    <img src={icon2} alt="icon-web-design" loading="lazy" />
                    <h2>
                      {
                        translate.takfilDigital.Websitedesignanddevelopment[
                          indexTranslate
                        ]
                      }
                    </h2>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="digital-box">
                    <img src={icon3} alt="icon-e-commerce" loading="lazy" />
                    <h2>{DigitalMarketing[indexTranslate]}</h2>
                  </div>
                </Col>
                <Col lg="3">
                <div className="digital-box">
                  <img src={productListingimage} alt="icon-e-commerce" loading="lazy" />
                  <h2>{productListing[indexTranslate]}</h2>
                </div>
              </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <div className="digital-box-des">
                    <p>
                      <img src={ecommerce} alt="icon" loading="lazy" />
                      {text1[indexTranslate]}
                    </p>
                    <p>
                      <img src={webdevelpment} alt="" loading="lazy" />
                      {text2[indexTranslate]} <br />
                      {text5[indexTranslate]}
                      <br />
                      {text6[indexTranslate]}
                      <br />
                      {text3[indexTranslate]}
                      <br />
                      {text4[indexTranslate]}
                      <br />
                      {text7[indexTranslate]}
                      <br />
                      {text8[indexTranslate]}
                      <br />
                      {text9[indexTranslate]}
                      <br />
                    </p>
                    <p>
                      <img src={digital} alt="" loading="lazy" />
                      {DigitalMarketingdes[indexTranslate]}
                    </p>
                    <p>
                    <img src={amazon} alt="" loading="lazy" />
                    {text10[indexTranslate]}
                  </p>
                  <p>
                  <img src={mobiledevelopment} alt="" loading="lazy" />
                  باستخدام أحدث التقنيات ، نساعد الشركات على تطوير تطبيقات الهاتف المحمول التي تعمل على تكاليف أنظمة أساسية متعددة مع تزويد المستخدمين لديك بتطبيق يناسب نظام التشغيل الخاص بهم. استخدم هذا البديل لتقليل التكاليف ووقت الوصول إلى السوق والوصول إلى المزيد من المستخدمين دون فقدان الجودة.
                </p>
                <p>
                    <img src={mantainesdevelopment} alt="" loading="lazy" />
                    نحن مصدر أساسي للحفاظ على عمل تطبيقاتك ، وموثوقيتها ، ووثيقة الصلة باحتياجات عملك المتطورة باستمرار. تغطي خدمات دعم وصيانة تطبيقات الويب لدينا كل شيء بدءًا من تحديثات المحتوى وبرمجة الويب وإصلاح الأخطاء ومراجعات التعليمات البرمجية ودعم قاعدة بيانات SQL , MYSQL والتحديثات ودعم تطبيقات الأجهزة المحمولة (iOS و Android) ودعم استضافة التطبيقات.
                  </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default TakfilDigital;
