import "./plan.css";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import { Container, Row, Col } from "react-bootstrap";
import Frame from "../utility/frame/Frame";
import { motion } from "framer-motion";
const Plans = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const {
    perMonth,
    basic,
    basicplanservices1,
    basicplanservices2,
    basicplanservices3,
    basicplanservices4,
    basicplanservices5,
    basicplanservices6,
    basicplanservices7,
    basicplanservices8,
    advanced,
    MostPopular,
    advanceplansservices1,
    advanceplansservices2,
    advanceplansservices3,
    advanceplansservices4,
    advanceplansservices5,
    Professional,
    professionalplansservices1,
    professionalplansservices2,
    professionalplansservices3,
    professionalplansservices4,
  } = {
    ...translate.plans,
  };
  return (
    <>
      {indexTranslate === 0 ? (
        <div className="pricing pt-5" id="pricing">
          <Container>
            <Frame title="Our Plans" />
            <Row className="mt-5">
              <Col lg="4" className="wow fadeInUp">
                <motion.div
                  className="box"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: false }}
                  transition={{ duration: 2 }}
                >
                  <div className="title">{basic[indexTranslate]}</div>
                  <img src="images/hosting-basic.png" alt="" />
                  <div className="price">
                    <span className="amount">2000</span>
                    <span className="time">{perMonth[indexTranslate]}</span>
                  </div>
                  <ul>
                    <li>{basicplanservices1[indexTranslate]}</li>
                    <li>{basicplanservices2[indexTranslate]}</li>
                    <li>{basicplanservices3[indexTranslate]}</li>
                    <li>{basicplanservices5[indexTranslate]}</li>
                    <li>{basicplanservices4[indexTranslate]}</li>
                    <li>{basicplanservices6[indexTranslate]}</li>
                    <li>{basicplanservices7[indexTranslate]}</li>
                    <li>{basicplanservices8[indexTranslate]}</li>
                  </ul>
                  <a href="#">Choose Plan</a>
                </motion.div>
              </Col>
              <Col lg="4" className="wow fadeInUp">
                {" "}
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: false }}
                  transition={{ duration: 2 }}
                  className="box popular"
                >
                  <div className="label">{MostPopular[indexTranslate]}</div>
                  <div className="title">{advanced[indexTranslate]}</div>
                  <img src="images/hosting-advanced.png" alt="" />
                  <div className="price">
                    <span className="amount">4000</span>
                    <span className="time">{perMonth[indexTranslate]}</span>
                  </div>
                  <ul>
                    <li>{basicplanservices1[indexTranslate]}</li>
                    <li>{basicplanservices2[indexTranslate]}</li>
                    <li>{basicplanservices3[indexTranslate]}</li>
                    <li>{basicplanservices5[indexTranslate]}</li>
                    <li>{advanceplansservices1[indexTranslate]}</li>
                    <li>{advanceplansservices2[indexTranslate]}</li>
                    <li>{advanceplansservices3[indexTranslate]}</li>
                    <li>{advanceplansservices4[indexTranslate]}</li>
                    <li>{advanceplansservices5[indexTranslate]}</li>
                  </ul>
                  <a href="#">Choose Plan</a>
                </motion.div>
              </Col>
              <Col lg="4" className="wow fadeInUp">
                <motion.div
                  className="box"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: false }}
                  transition={{ duration: 2 }}
                >
                  <div className="title">{Professional[indexTranslate]}</div>
                  <img src="images/hosting-professional.png" alt="" />
                  <div className="price">
                    <span className="amount">2500</span>
                    <span className="time">{perMonth[indexTranslate]}</span>
                  </div>
                  <ul>
                    <li>{basicplanservices1[indexTranslate]}</li>
                    <li>{basicplanservices2[indexTranslate]}</li>
                    <li>{basicplanservices3[indexTranslate]}</li>
                    <li>{basicplanservices5[indexTranslate]}</li>
                    <li>{basicplanservices4[indexTranslate]}</li>
                    <li>{professionalplansservices1[indexTranslate]}</li>
                    <li>{professionalplansservices2[indexTranslate]}</li>
                    <li>{professionalplansservices3[indexTranslate]}</li>
                    <li>{professionalplansservices4[indexTranslate]}</li>
                  </ul>
                  <a href="#">Choose Plan</a>
                </motion.div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div
          className="pricing pricing-ar pt-5"
          id="pricing"
          style={{ direction: "rtl" }}
        >
          <Container>
            <Frame title="خطط الاشتراك" />
            <Row className="mt-5">
              <Col lg="4" className="wow fadeInUp" data-wow-delay="1s">
                <motion.div
                  className="box"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: false }}
                  transition={{ duration: 2 }}
                >
                  <div className="title">{basic[indexTranslate]}</div>
                  <img src="images/hosting-basic.png" alt="" />
                  <div className="price">
                    <span className="amount">2000</span>
                    <span className="time">{perMonth[indexTranslate]}</span>
                  </div>
                  <ul>
                    <li>{basicplanservices1[indexTranslate]}</li>
                    <li>{basicplanservices2[indexTranslate]}</li>
                    <li>{basicplanservices3[indexTranslate]}</li>
                    <li>{basicplanservices5[indexTranslate]}</li>
                    <li>{basicplanservices4[indexTranslate]}</li>
                    <li>{basicplanservices6[indexTranslate]}</li>
                    <li>{basicplanservices7[indexTranslate]}</li>
                    <li>{basicplanservices8[indexTranslate]}</li>
                  </ul>
                  <a href="#"> اختر الخطة</a>
                </motion.div>
              </Col>
              <Col lg="4" className="wow fadeInUp">
                <motion.div
                  className="box popular"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: false }}
                  transition={{ duration: 2 }}
                >
                  <div className="label">{MostPopular[indexTranslate]}</div>
                  <div className="title">{advanced[indexTranslate]}</div>
                  <img src="images/hosting-advanced.png" alt="" />
                  <div className="price">
                    <span className="amount">4000</span>
                    <span className="time">{perMonth[indexTranslate]}</span>
                  </div>
                  <ul>
                    <li>{basicplanservices1[indexTranslate]}</li>
                    <li>{basicplanservices2[indexTranslate]}</li>
                    <li>{basicplanservices3[indexTranslate]}</li>
                    <li>{basicplanservices5[indexTranslate]}</li>
                    <li>{advanceplansservices1[indexTranslate]}</li>
                    <li>{advanceplansservices2[indexTranslate]}</li>
                    <li>{advanceplansservices3[indexTranslate]}</li>
                    <li>{advanceplansservices4[indexTranslate]}</li>
                    <li>{advanceplansservices5[indexTranslate]}</li>
                  </ul>
                  <a href="#"> اختر الخطة</a>
                </motion.div>
              </Col>
              <Col lg="4" className="wow fadeInUp" data-wow-delay="2s">
                <motion.div
                className="box "
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: false }}
                transition={{ duration: 2 }}>
                  <div className="title">{Professional[indexTranslate]}</div>
                  <img src="images/hosting-professional.png" alt="" />
                  <div className="price">
                    <span className="amount">2500</span>
                    <span className="time">{perMonth[indexTranslate]}</span>
                  </div>
                  <ul>
                    <li>{basicplanservices1[indexTranslate]}</li>
                    <li>{basicplanservices2[indexTranslate]}</li>
                    <li>{basicplanservices3[indexTranslate]}</li>
                    <li>{basicplanservices5[indexTranslate]}</li>
                    <li>{basicplanservices4[indexTranslate]}</li>
                    <li>{professionalplansservices1[indexTranslate]}</li>
                    <li>{professionalplansservices2[indexTranslate]}</li>
                    <li>{professionalplansservices3[indexTranslate]}</li>
                    <li>{professionalplansservices4[indexTranslate]}</li>
                  </ul>
                  <a href="#"> اختر الخطة</a>
                </motion.div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Plans;
