import img1 from "../../assets/images/img-1.webp";
import img2 from "../../assets/images/img-2.webp";
import img3 from "../../assets/images/img-3.webp";
import img4 from "../../assets/images/img-4.webp";
import img5 from "../../assets/images/img-5.webp";
import img6 from "../../assets/images/img-6.webp";
import img7 from "../../assets/images/img-7.webp";
import img8 from "../../assets/images/img-8.webp";
import img9 from "../../assets/images/img-9.webp";
import img10 from "../../assets/images/img-10.webp";
import "./section4.css";


const Section4 = () => {
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];
  const links = [
    "https://www.gdrfad.gov.ae/ar/customer-happiness-centers",
    "https://eservices.mohre.gov.ae/MOHRE.Domestic.UI/DomesticTransactionEntry/Index?lang=ar-ae",
    "https://icp.gov.ae/",
    "https://moamalat.ae/ar/tasheel/",
    "https://www.dubaichamber.com/ar/home/",
    "https://ded.ae/",
    "https://www.rta.ae/wps/portal/rta/ae/home/!ut/p/z0/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zi_QwMTNwNTAx93EPNDAwcQ4MCA8O8gowNXMz1gxOL9AuyHRUBwhZb3Q!!/",
    "https://dubailand.gov.ae/#/",
    "https://www.dm.gov.ae/",
    "https://tax.gov.ae/ar/default.aspx",
  ];
  return (
    <>
    <div className="section-4">
      <div className="slider">
        <div className="slide-track">
          {images.map((img, index) => {
            return (
              <a
                href={links[index]}
                className="slide"
                key={img}
                target="_blank"
                rel="noreferrer"
              >
                <img src={img} alt="" loading="lazy"/>
              </a>
            );
          })}
        </div>
      </div>
    </div>

  </>
  );
};

export default Section4;
