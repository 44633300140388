import { Tilt } from "react-tilt";
import "./testslider.css";
import { FaStar } from "react-icons/fa";
const testSlider = ({ images, texts }) => {
  return (
    <div className="slider">
      <div className="slide-track">
        {images.map((slide, index) => {
          return (
            <Tilt className="slide" key={slide}>
              <img src={slide} alt="" />
              <h5>{texts[index]} </h5>
              <div className="reviews-star">
                <FaStar style={{ color: "gold" }} />
                <FaStar style={{ color: "gold" }} />
                <FaStar style={{ color: "gold" }} />
                <FaStar style={{ color: "gold" }} />
                <FaStar style={{ color: "gold" }} />
              </div>
            </Tilt>
          );
        })}
      </div>
    </div>
  );
};

export default testSlider;
