
import Company from '../../../components/company/Company'
import { companyName, logos } from '../../../data/data'
import kenz1 from '../../../assets/images/kenz/kenz1.jfif'
import kenz2 from '../../../assets/images/kenz/kenz2.jfif'
import kenz3 from '../../../assets/images/kenz/kenz3.jfif'
import kenz4 from '../../../assets/images/kenz/kenz4.jfif'
import IndexTranslate from '../../../atoms/translateIndex'
import { useRecoilState } from 'recoil'


export const kenzImages=[
  kenz1,
  kenz2,
  kenz3,
  kenz4,
]
const Kenz = () => {
  const newImages =kenzImages.map(image =>{
    return {
      original: image,
      thumbnail: image,
      originalHeight: "300px",
    }
  })
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="section-top">
    <Company
      companyName={companyName[5][indexTranslate]}
      companyLogo={logos[5]}
      images={newImages}
    />
  </div>
  )
}

export default Kenz
