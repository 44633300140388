import { useState } from "react";
import "./contact.css";
import { Container, Row, Col } from "react-bootstrap";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import emailIcon from "../../assets/images/message.webp";
import { ToastContainer } from "react-toastify";
import notify from "../../hook/useNotifaction";
import axios from "axios";
import Frame from "../../components/utility/frame/Frame";
import Header from "../../components/utility/header/Header";
import { MdLocationOn, MdAddIcCall, MdEmail } from "react-icons/md";
import { Helmet } from "react-helmet-async";

const ContactUs = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const { contactUs } = { ...translate.nav };
  const {
    name,
    email,
    phone,
    message,
    send,
    enterName,
    enteremail,
    enterphone,
    entermessage,
    contactmessage,
    warnName,
    warnEmail,
    warnPhone,
    warnMessage,
    sucessDone,
  } = {
    ...translate.form,
  };

  const [inputValues, setInputValues] = useState({
    name: undefined,
    email: undefined,
    phone: undefined,
    message: undefined,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (inputValues.name) {
      formData.append("name", inputValues.name);
    } else {
      notify(warnName[indexTranslate], "warn");
      return;
    }
    if (inputValues.email) {
      formData.append("email", inputValues.email);
    } else {
      notify(warnEmail[indexTranslate], "warn");
      return;
    }
    if (inputValues.phone && !isNaN(parseInt(inputValues.phone))) {
      formData.append("phone", inputValues.phone);
    } else {
      notify(warnPhone[indexTranslate], "warn");
      return;
    }
    if (inputValues.message && inputValues.message.length > 20) {
      formData.append("message", inputValues.message);
      await axios
        .post("php/contact.php", formData)
        .then(() => notify(sucessDone[indexTranslate], "success"))
        .catch((error) => {
          notify("something wrong", "error");
        });
    } else {
      notify(warnMessage[indexTranslate], "warn");
      return;
    }
    setInputValues((prevState) => ({ ...prevState, name: "" }));
    setInputValues((prevState) => ({ ...prevState, email: "" }));
    setInputValues((prevState) => ({ ...prevState, phone: "" }));
    setInputValues((prevState) => ({ ...prevState, message: "" }));
  };
  function handleInputChange(e) {
    const { name, value } = e.target;
    setInputValues((prevState) => ({ ...prevState, [name]: value }));
  }

  return (
    <>
      {indexTranslate === 0 ? (
        <Helmet>
          <meta
            name="description"
            content="if you have questions or just want to get on touch use the form below.we look forward to hearing from you"
          />
          <title>Takfil | Contact Us</title>
        </Helmet>
      ) : (
        <Helmet>
          <meta
            name="description"
            content="if you have questions or just want to get on touch use the form below.we look forward to hearing from you"
          />
          <meta
            name="description"
            content="إذا كان لديك أسئلة أو ترغب فقط في التواصل، استخدم النموذج التالي. نحن نتطلع إلى التواصل معك."
          />
          <meta
            name="keywords"
            content=" ابدأ بزيادة عدد متابعينك , تواصل معنا ,اطلب متجرك الالكتروني الأن ,ابدأ التسويق لعملك معنا"
          />
          <title> تكفيل | تواصل معنا</title>
        </Helmet>
      )}
      {indexTranslate === 0 ? (
        <div className="contact-us ">
          <Header link="/contactUs" secondPage={contactUs[indexTranslate]} />
          <Container>
            <Frame title={contactUs[indexTranslate]} />

            <Row className="mt-md-5">
              <Col lg="6">
                <div className="contact-us-box">
                  <img src={emailIcon} alt="email-icon" loading="lazy" />
                  <p>{contactmessage[indexTranslate]}</p>
                  <div className="contact-us-box-2">
                    <div className="contact-box">
                      <MdAddIcCall />
                      <p>+971-0551204449</p>
                    </div>
                    <div className="contact-box">
                      <MdLocationOn />
                      <p>Deira - Riggat Al Buteen - Dubai</p>
                    </div>
                    <div className="contact-box">
                      <MdEmail />
                      <p>contact@takfil.net</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <form>
                  <label>
                    {name[indexTranslate]}
                    <input
                      type="text"
                      placeholder={enterName[indexTranslate]}
                      value={inputValues.name}
                      onChange={handleInputChange}
                      name="name"
                    />
                  </label>
                  <label>
                    {email[indexTranslate]}
                    <input
                      type="text"
                      placeholder={enteremail[indexTranslate]}
                      value={inputValues.email}
                      onChange={handleInputChange}
                      name="email"
                    />
                  </label>
                  <label>
                    {phone[indexTranslate]}
                    <input
                      type="number"
                      placeholder={enterphone[indexTranslate]}
                      value={inputValues.phone}
                      onChange={handleInputChange}
                      name="phone"
                    />
                  </label>
                  <label>
                    {message[indexTranslate]}
                    <textarea
                      placeholder={entermessage[indexTranslate]}
                      value={inputValues.message}
                      onChange={handleInputChange}
                      name="message"
                    />
                  </label>
                  <input
                    type="submit"
                    value={send[indexTranslate]}
                    onClick={handleSubmit}
                  />
                </form>
              </Col>
            </Row>
          </Container>
          <ToastContainer position="top-center" theme="dark" />
        </div>
      ) : (
        <div className="contact-us contact-us-ar" style={{ direction: "rtl" }}>
          <Header link="/contactUs" secondPage={contactUs[indexTranslate]} />
          <Container>
            <Frame title={contactUs[indexTranslate]} />
            <Row className="mt-md-5">
              <Col lg="6">
                <div className="contact-us-box">
                  <img src={emailIcon} alt="email-icon" loading="lazy" />
                  <p>{contactmessage[indexTranslate]}</p>
                  <div className="contact-us-box-2">
                    <div className="contact-box">
                      <MdAddIcCall />
                      <p style={{ direction: "ltr" }}>+971-0551204449</p>
                    </div>
                    <div className="contact-box">
                      <MdLocationOn />
                      <p>Deira - Riggat Al Buteen - Dubai</p>
                    </div>
                    <div className="contact-box">
                      <MdEmail />
                      <p>contact@takfil.net</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <form>
                  <label>
                    {name[indexTranslate]}
                    <input
                      type="text"
                      placeholder={enterName[indexTranslate]}
                      value={inputValues.name}
                      onChange={handleInputChange}
                      name="name"
                    />
                  </label>
                  <label>
                    {email[indexTranslate]}
                    <input
                      type="text"
                      placeholder={enteremail[indexTranslate]}
                      value={inputValues.email}
                      onChange={handleInputChange}
                      name="email"
                    />
                  </label>
                  <label>
                    {phone[indexTranslate]}
                    <input
                      type="number"
                      placeholder={enterphone[indexTranslate]}
                      value={inputValues.phone}
                      onChange={handleInputChange}
                      name="phone"
                    />
                  </label>
                  <label>
                    {message[indexTranslate]}
                    <textarea
                      placeholder={entermessage[indexTranslate]}
                      value={inputValues.message}
                      onChange={handleInputChange}
                      name="message"
                    />
                  </label>
                  <input
                    type="submit"
                    value={send[indexTranslate]}
                    onClick={handleSubmit}
                  />
                </form>
              </Col>
            </Row>
          </Container>
          <ToastContainer position="top-center" theme="dark" />
        </div>
      )}
    </>
  );
};

export default ContactUs;
