import "./ourcustomer.css";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import { Link } from "react-router-dom";
import { links, logos } from "../../data/data";
import useGoToTop from "../../hook/useGoToTop";
import Frame from "../utility/frame/Frame";

const OurCustomers = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <>
      <div className="our-customers">
        <Frame title={translate.ourCustomers.title[indexTranslate]} />
        <div className="rotate-slide-container container">
          <div className="rotate-slide">
            {logos.slice(1, 12).map((logo, index) => {
              return (
                <span style={{ "--i": index + 1 }} key={logo}>
                  <Link to={links[index]} onClick={useGoToTop}>
                    <img src={logo} alt="" />
                  </Link>
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurCustomers;
