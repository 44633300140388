import './header.css'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil';
import TranslateAtom from '../../../atoms/TranslateAtom';
import IndexTranslate from '../../../atoms/translateIndex';

const Header = ({link,secondPage}) => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className='header-link wow zoomIn'>
    <Container>
    <h1>
    <Link to='/'>{translate.nav.home[indexTranslate]}</Link>{"/"}<Link to={link}>{secondPage}</Link>
    </h1>
    </Container>
 
      </div>
  )
}

export default Header
