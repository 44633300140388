import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import video1 from "../../assets/videos/video2.webm";
import video2 from "../../assets/videos/video2.mp4";
import "./section3.css";


const Section3 = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const {
    title,
    description,
    icon1title,
    icon1des,
    icon2title,
    icon2des,
    icon3title,
    icon3des,
    icon4title,
    icon4des,
  } = {
    ...translate.section3,
  };
  return (
    <>
      {indexTranslate === 0 ? (
        <div className="section-3">
          <Container>
            <Row className="section-3-container">
              <Col lg="7">
                <h5>{title[indexTranslate]}</h5>
                <p>{description[indexTranslate]}</p>
                <Row>
                  <Col lg="6">
                    <div className="box-section-3">
                      <div className="box-icon-section-3">
                        <lord-icon
                          src="https://cdn.lordicon.com/sbiheqdr.json"
                          trigger="loop"
                          colors="primary:#ffffff,secondary:#00992a"
                          style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                      </div>
                      <div className="box-content-section-3">
                        <h6>{icon1title[indexTranslate]}</h6>
                        <p>{icon1des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="box-section-3">
                      <div className="box-icon-section-3">
                      <lord-icon
                      src="https://cdn.lordicon.com/uqpazftn.json"
                      trigger="loop"
                      colors="primary:#ffffff,secondary:#00992a"
                      style={{ width: "100px", height: "100px" }}>
                  </lord-icon>
                      </div>
                      <div className="box-content-section-3">
                        <h6>{icon3title[indexTranslate]}</h6>
                        <p>{icon3des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="box-section-3">
                      <div className="box-icon-section-3">
                        <lord-icon
                          src="https://cdn.lordicon.com/msoeawqm.json"
                          trigger="loop"
                          colors="primary:#ffffff,secondary:#00992a"
                          style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                      </div>
                      <div className="box-content-section-3">
                        <h6>{icon2title[indexTranslate]}</h6>
                        <p>{icon2des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="box-section-3">
                      <div className="box-icon-section-3">
                      <lord-icon
                      src="https://cdn.lordicon.com/qhviklyi.json"
                      trigger="loop"
                      colors="primary:#ffffff,secondary:#00992a"
                      style={{ width: "100px", height: "100px" }}>
                  </lord-icon>
                      </div>
                      <div className="box-content-section-3">
                        <h6>{icon4title[indexTranslate]}</h6>
                        <p>{icon4des[indexTranslate]}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="5">
                <video controls>
                  <source src={video1} type="video/webm" />
                  <source src={video2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="section-3" style={{ direction: "rtl" }}>
        <Container>
        <Row className="section-3-container">
          <Col lg="7">
            <h5>{title[indexTranslate]}</h5>
            <p>{description[indexTranslate]}</p>
            <Row>
              <Col lg="6">
                <div className="box-section-3">
                  <div className="box-icon-section-3">
                    <lord-icon
                      src="https://cdn.lordicon.com/sbiheqdr.json"
                      trigger="loop"
                      colors="primary:#ffffff,secondary:#00992a"
                      style={{ width: "100px", height: "100px" }}
                    ></lord-icon>
                  </div>
                  <div className="box-content-section-3">
                    <h6>{icon1title[indexTranslate]}</h6>
                    <p>{icon1des[indexTranslate]}</p>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="box-section-3">
                  <div className="box-icon-section-3">
                  <lord-icon
                  src="https://cdn.lordicon.com/uqpazftn.json"
                  trigger="loop"
                  colors="primary:#ffffff,secondary:#00992a"
                  style={{ width: "100px", height: "100px" }}>
              </lord-icon>
                  </div>
                  <div className="box-content-section-3">
                    <h6>{icon3title[indexTranslate]}</h6>
                    <p>{icon3des[indexTranslate]}</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="box-section-3">
                  <div className="box-icon-section-3">
                    <lord-icon
                      src="https://cdn.lordicon.com/msoeawqm.json"
                      trigger="loop"
                      colors="primary:#ffffff,secondary:#00992a"
                      style={{ width: "100px", height: "100px" }}
                    ></lord-icon>
                  </div>
                  <div className="box-content-section-3">
                    <h6>{icon2title[indexTranslate]}</h6>
                    <p>{icon2des[indexTranslate]}</p>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="box-section-3">
                  <div className="box-icon-section-3">
                  <lord-icon
                  src="https://cdn.lordicon.com/qhviklyi.json"
                  trigger="loop"
                  colors="primary:#ffffff,secondary:#00992a"
                  style={{ width: "100px", height: "100px" }}>
              </lord-icon>
                  </div>
                  <div className="box-content-section-3">
                    <h6>{icon4title[indexTranslate]}</h6>
                    <p>{icon4des[indexTranslate]}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg="5">
            <video controls>
              <source src={video1} type="video/webm" />
              <source src={video2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Col>
        </Row>
      </Container>
        </div>
      )}
    </>
  );
};

export default Section3;
