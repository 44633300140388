import Company from "../../../components/company/Company"
import { companyName, logos } from "../../../data/data";
import qasr1 from '../../../assets/images/Qasr/qasr1.jfif'
import qasr2 from '../../../assets/images/Qasr/qasr2.jfif'
import qasr4 from '../../../assets/images/Qasr/qasr4.webp'
import qasr5 from '../../../assets/images/Qasr/qasr5.webp'
import qasr6 from '../../../assets/images/Qasr/qasr6.webp'
import qasr7 from '../../../assets/images/Qasr/qasr7.webp'

import { useRecoilState } from "recoil";
import IndexTranslate from "../../../atoms/translateIndex";

export const qasrImages=[
  qasr1,
  qasr2,
  qasr4,
  qasr5,
  qasr6,
  qasr7,
]
const QasrAlrand = () => {
  const newImages =qasrImages.map(image =>{
    return {
      original: image,
      thumbnail: image,
      originalHeight: "300px",
    }
  })
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="section-top">
      <Company
        companyName={companyName[4][indexTranslate]}
        companyLogo={logos[4]}
        images={newImages}
      />
    </div>
  )
}

export default QasrAlrand
