import { Col, Container, Row } from "react-bootstrap";
import "./company.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./style.css";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";


import ImageGallery from "react-image-gallery";
import Frame from "../utility/frame/Frame";
const Company = ({ companyName, companyLogo, images }) => {
  console.log(images);
  return (
    <div className="company-section">
    <Frame title={companyName}/>
      <Container>
        <Row className="mt-5">
          <Col md="6">
            <div className="company-logo">
              <img src={companyLogo} alt="company-logo" loading="lazy"/>
            </div>
          </Col>
          <Col md="6">
            {
            //   <ImageGallery
            //   items={images}
            //   autoPlay={true}
            //   showFullscreenButton={false}
            //   showPlayButton={false}
            //   loading="lazy"
            // />
          }
          {
            <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            {
              images.map((image)=>{
                return(
                  <SwiperSlide>
                  <img src={image.original} alt={image}/>
                </SwiperSlide>
                )
              })
            }
           
          </Swiper>
          }
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Company;
