import "./takfil.css";
import document from "../../assets/images/document.webp";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import { Tabs, Tab } from "react-bootstrap";
import Frame from "../../components/utility/frame/Frame";
import Header from "../../components/utility/header/Header";
import { Helmet } from "react-helmet-async";
const Takfil = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const {
    description,
    ourServices,
    Transactionsdes,
    Transactions,
    AttestationServices,
    AttestationServicesdes,
    Establishmentofcompanies,
    Establishmentofcompaniesdes,
    Printing,
    Printingdes,
    OtherServices,
    OtherServicesdes,
  } = {
    ...translate.takfil,
  };
  return (
    <>
      {indexTranslate === 0 ? (
        <Helmet>
          <meta
            name="description"
            content=""
          />

          <title>Takfil | Business Services for Document Clearance</title>
          <meta
          name="description"
          content="Efficient Document Clearance Services for Businesses: License Renewal, Residency Management, Government Transactions | Consult Now!"
        />
        </Helmet>
      ) : (
        <Helmet>

          <title>   تكفيل لخدمة متابعة المعاملات</title>
        </Helmet>
      )}
      {indexTranslate === 0 ? (
        <div className="takfil-document-services">
          <Header
            link="/takfil"
            secondPage={
              translate.nav.TakfilDocumentsClearingServices[indexTranslate]
            }
          />
          <Frame
            title={
              translate.nav.TakfilDocumentsClearingServices[indexTranslate]
            }
          />
          <div className="container mt-md-5">
            <div className="row">
              <div className="col-lg-6 document-box-1 ">
                <img src={document} alt="" loading="lazy"  />
              </div>
              <div className="col-lg-6 document-box-2  description">
                <p>{description[indexTranslate]}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="takfil-document-services" style={{ direction: "rtl" }}>
          <Header
            link="/takfil"
            secondPage={
              translate.nav.TakfilDocumentsClearingServices[indexTranslate]
            }
          />
          <Frame
            title={
              translate.nav.TakfilDocumentsClearingServices[indexTranslate]
            }
          />
          <div className="container mt-md-5">
            <div className="row">
              <div className="col-lg-6 document-box-1 ">
                <img src={document} alt="" loading="lazy" />
              </div>
              <div className="col-lg-6 document-box-2  description">
                <p>{description[indexTranslate]}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {indexTranslate === 0 ? (
        <div className="our-services">
          <Frame title={ourServices[indexTranslate]} />
          <div className="container buttons mb-30  mt-md-5">
            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab eventKey={1} title={Transactions[indexTranslate]}>
                <p>{Transactionsdes[indexTranslate]}</p>
              </Tab>
              <Tab eventKey={2} title={AttestationServices[indexTranslate]}>
                {AttestationServicesdes[indexTranslate].map((service) => {
                  return <p key={service}>{service}</p>;
                })}
              </Tab>
              <Tab
                eventKey={3}
                title={Establishmentofcompanies[indexTranslate]}
              >
                {Establishmentofcompaniesdes[indexTranslate].map((service) => {
                  return <p key={service}>{service}</p>;
                })}
              </Tab>
              <Tab eventKey={4} title={Printing[indexTranslate]}>
                {Printingdes[indexTranslate].map((service) => {
                  return <p key={service}> {service}</p>;
                })}
              </Tab>
              <Tab eventKey={5} title={OtherServices[indexTranslate]}>
                {OtherServicesdes[indexTranslate].map((service) => {
                  return <p key={service}>{service}</p>;
                })}
              </Tab>
            </Tabs>
          </div>
        </div>
      ) : (
        <div className="our-services" style={{ direction: "rtl" }}>
          <Frame title={ourServices[indexTranslate]} />
          <div className="container buttons mb-30  mt-md-5">
            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab eventKey={1} title={Transactions[indexTranslate]}>
                <p> {Transactionsdes[indexTranslate]}</p>
              </Tab>
              <Tab eventKey={2} title={AttestationServices[indexTranslate]}>
                {AttestationServicesdes[indexTranslate].map((service) => {
                  return <p key={service}>{service}</p>;
                })}
              </Tab>
              <Tab
                eventKey={3}
                title={Establishmentofcompanies[indexTranslate]}
              >
                {Establishmentofcompaniesdes[indexTranslate].map((service) => {
                  return <p key={service}>{service}</p>;
                })}
              </Tab>
              <Tab eventKey={4} title={Printing[indexTranslate]}>
                {Printingdes[indexTranslate].map((service) => {
                  return <p key={service}> {service}</p>;
                })}
              </Tab>
              <Tab eventKey={5} title={OtherServices[indexTranslate]}>
                {OtherServicesdes[indexTranslate].map((service) => {
                  return <p key={service}>{service}</p>;
                })}
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};

export default Takfil;
