import "./topNav.css";
import { MdLocationOn, MdAddIcCall } from "react-icons/md";
import {
    BsFacebook,
    BsWhatsapp,

    BsInstagram,

  } from "react-icons/bs";
 
const TopNav = () => {
  return (
    <div className="top-nav">
      <div className="box">
        <p style={{direction:'ltr'}}>+971551204449 </p>
        <MdAddIcCall />
      </div>
      <div className="box">
        <p>B62 - 201c Al Maktoum Rd - Deira - Riggat Al Buteen - Dubai</p>
        <MdLocationOn />
      </div>
      <div className="box">
      <ul className="social">
        <li>
          <a
            href="https://www.facebook.com/takfil.digital.marketing"
            target="_blank"
            rel="noreferrer"
            className="facebook"
          >
            <BsFacebook />
          </a>
        </li>
        <li>
          <a
            href=" https://wa.me/971526406447?text=I'm%20interested%20to%20know%20more%20about%20the%20services"
            target="_blank"
            rel="noreferrer"
            className="whatsapp"
          >
            <BsWhatsapp />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/takfil.tdm/"
            target="_blank"
            rel="noreferrer"
            className="instagram"
          >
            <BsInstagram />
          </a>
        </li>
      </ul>
    </div>
    </div>
  );
};

export default TopNav;
