import React from "react";
import "./section2.css";
import { Container, Row, Col } from "react-bootstrap";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import { Tilt } from "react-tilt";



const Section2 = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const {
    icon2title,
    icon2des,
    icon3title,
    icon3des,
    icon4title,
    icon4des,
    icon5title,
    icon5des,
  } = {
    ...translate.section2,
  };
  return (
    <>
      {indexTranslate === 0 ? (
        <div className="section-2">
          <Container>
            <h3>{translate.section2.title[indexTranslate]}</h3>
            <Row className="icon-container">
              <Tilt md="3" className="box-icon  ">
                <lord-icon
                  src="https://cdn.lordicon.com/nocovwne.json"
                  trigger="loop"
                  colors="primary:#ffffff,secondary:#00992a"
                  style={{
                    width: "125px",
                    height: "125px",
                    marginBottom: "15px",
                  }}
                  loading="lazy"
                ></lord-icon>
                <h4>{icon2title[indexTranslate]}</h4>
                <p>{icon2des[indexTranslate]}</p>
              </Tilt>
              <Tilt md="3" className="box-icon  ">
                <lord-icon
                  src="https://cdn.lordicon.com/lupuorrc.json"
                  trigger="loop"
                  colors="primary:#ffffff,secondary:#00992a"
                  style={{
                    width: "125px",
                    height: "125px",
                    marginBottom: "15px",
                  }}
                  loading="lazy"
                ></lord-icon>
                <h4>{icon3title[indexTranslate]}</h4>
                <p>{icon3des[indexTranslate]}</p>
              </Tilt>
              <Tilt md="3" className="box-icon  ">
                <lord-icon
                  src="https://cdn.lordicon.com/zpxybbhl.json"
                  trigger="loop"
                  colors="primary:#ffffff,secondary:#00992a"
                  style={{
                    width: "125px",
                    height: "125px",
                    marginBottom: "15px",
                  }}
                  loading="lazy"
                ></lord-icon>
                <h4>{icon4title[indexTranslate]}</h4>
                <p>{icon4des[indexTranslate]}</p>
              </Tilt>
              <Tilt md="3" className="box-icon  ">
                <lord-icon
                  src="https://cdn.lordicon.com/bgwzirmj.json"
                  trigger="loop"
                  colors="primary:#00992a,secondary:#fff"
                  style={{
                    width: "125px",
                    height: "125px",
                    marginBottom: "15px",
                  }}
                  loading="lazy"
                ></lord-icon>
                <h4>{icon5title[indexTranslate]}</h4>
                <p>{icon5des[indexTranslate]}</p>
              </Tilt>
            </Row>
          </Container>
        </div>
      ) : (
        <div className="section-2" style={{ direction: "rtl" }}>
          <Container>
            <h3>{translate.section2.title[indexTranslate]}</h3>
            <Row className="icon-container">
              <Tilt md="3" className="box-icon  ">
                <lord-icon
                  src="https://cdn.lordicon.com/nocovwne.json"
                  trigger="loop"
                  colors="primary:#ffffff,secondary:#00992a"
                  style={{
                    width: "125px",
                    height: "125px",
                    marginBottom: "15px",
                  }}
                  loading="lazy"
                ></lord-icon>
                <h4>{icon2title[indexTranslate]}</h4>
                <p>{icon2des[indexTranslate]}</p>
              </Tilt>
              <Tilt md="3" className="box-icon  ">
                <lord-icon
                  src="https://cdn.lordicon.com/lupuorrc.json"
                  trigger="loop"
                  colors="primary:#ffffff,secondary:#00992a"
                  style={{
                    width: "125px",
                    height: "125px",
                    marginBottom: "15px",
                  }}
                  loading="lazy"
                ></lord-icon>
                <h4>{icon3title[indexTranslate]}</h4>
                <p>{icon3des[indexTranslate]}</p>
              </Tilt>
              <Tilt md="3" className="box-icon  ">
                <lord-icon
                  src="https://cdn.lordicon.com/zpxybbhl.json"
                  trigger="loop"
                  colors="primary:#ffffff,secondary:#00992a"
                  style={{
                    width: "125px",
                    height: "125px",
                    marginBottom: "15px",
                  }}
                  loading="lazy"
                ></lord-icon>
                <h4>{icon4title[indexTranslate]}</h4>
                <p>{icon4des[indexTranslate]}</p>
              </Tilt>
              <Tilt md="3" className="box-icon  ">
                <lord-icon
                  src="https://cdn.lordicon.com/bgwzirmj.json"
                  trigger="loop"
                  colors="primary:#00992a,secondary:#fff"
                  style={{
                    width: "125px",
                    height: "125px",
                    marginBottom: "15px",
                  }}
                  loading="lazy"
                ></lord-icon>
                <h4>{icon5title[indexTranslate]}</h4>
                <p>{icon5des[indexTranslate]}</p>
              </Tilt>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Section2;
