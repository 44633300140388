import servicesLogo from "../../assets/images/servicesLogo.webp";
import marketingLogo from "../../assets/images/marketingLogo.webp";
import "./navagation.css";
import { Container  } from "react-bootstrap";
import ar from "../../assets/images/ar.webp";
import en from "../../assets/images/en.webp";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
import { Link } from "react-router-dom";
import useCloseMenuWithGoTop from "../../hook/useCloseMenu";
import TopNav from "../TopNav/TopNav";

const Navigation = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate, setIndexTranslate] = useRecoilState(IndexTranslate);
  const {
    TakfilDocumentsClearingServices,
    TakfilDigitalMarketing,
    home,
    ourTeams,
    aboutUs,
    contactUs,
    portfolio,
  } = { ...translate.nav };
  const navBurger = () => {
    document.querySelector(".nav-burger").classList.toggle("active");
    document.querySelector(".navLinks").classList.toggle("active");
  };
  return (
    <>
      {indexTranslate === 0 ? (
        <div className="navigation">
        <TopNav/>
          <Container>
            <nav>
              <div className="navBar">
                <div className="logo">
                  <Link to="/" aria-label='go to home page'>
                    <div className="logo">
                      <div className="flip-container">
                        <div className="flipper">
                          <div className="front">
                            <img src={servicesLogo} alt="" loading="lazy" />
                          </div>
                          <div className="back">
                            <img src={marketingLogo} alt=""  loading="lazy"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="nav-burger" onClick={navBurger}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <ul className="navLinks">
                  <li
                    className="navLink wow rollIn "
                    onClick={useCloseMenuWithGoTop}
                  
                  >
                    <Link to="/takfil"   aria-label='go to Takfil Documents Clearing Services page'>
                      {TakfilDocumentsClearingServices[indexTranslate]}
                    </Link>
                  </li>
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                   
                  >
                    <Link to="/takfilDigitalMarketing"  aria-label='go to Takfil digital marketing page'>
                      {TakfilDigitalMarketing[indexTranslate]}
                    </Link>
                  </li>
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                    
                  >
                    <Link to="/" aria-label='go to home page'>{home[indexTranslate]}</Link>
                  </li>
                                  <li
                    className="navLink wow rollIn "
                    onClick={useCloseMenuWithGoTop}
                   
                  >
                    <Link to="/aboutUs"  aria-label='go to about us page'>{aboutUs[indexTranslate]}</Link>
                  </li>
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                  
                  >
                    <Link to="/Portfolio"   aria-label='go to portfolio page'>{portfolio[indexTranslate]}</Link>
                  </li>
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                 
                  >
                    <Link to="/contactUs"    aria-label='go to contact us page'>{contactUs[indexTranslate]}</Link>
                  </li>
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                  >
                    <div className="lang">
                      <img
                        src={ar}
                        alt=""
                        loading="lazy"
                        onClick={() => setIndexTranslate(1)}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </Container>
  
        </div>
      ) : (
        <div className="navigation" style={{ direction: "rtl" }}>
        <TopNav/>
          <Container>
            <nav>
              <div className="navBar">
                <div className="logo">
                  <Link to="/"  aria-label='go to home page'>
                    <div className="logo">
                      <div className="flip-container">
                        <div className="flipper">
                          <div className="front">
                            <img src={servicesLogo} alt="" loading="lazy" />
                          </div>
                          <div className="back">
                            <img src={marketingLogo} alt=""  loading="lazy"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="nav-burger" onClick={navBurger}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <ul className="navLinks">
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                  >
                    <Link to="/takfil"  aria-label='go to Takfil Documents Clearing Services page'>
                      {TakfilDocumentsClearingServices[indexTranslate]}
                    </Link>
                  </li>
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                  >
                    <Link to="/takfilDigitalMarketing" aria-label='go to Takfil digital marketing page' >
                      {TakfilDigitalMarketing[indexTranslate]}
                    </Link>
                  </li>
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                  >
                    <Link to="/"    aria-label='go to home page'>{home[indexTranslate]}</Link>
                  </li>
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                  >
                    <Link to="/aboutUs"    aria-label='go to about us page'>{aboutUs[indexTranslate]}</Link>
                  </li>
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                  >
                    <Link to="/Portfolio"    aria-label='go to portfolio page'>{portfolio[indexTranslate]}</Link>
                  </li>
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                  >
                    <Link to="/contactUs"    aria-label='go to contact us page'>{contactUs[indexTranslate]}</Link>
                  </li>
                  <li
                    className="navLink wow rollIn"
                    onClick={useCloseMenuWithGoTop}
                  >
                    <div className="lang">
                      <img
                        src={en}
                        alt=""
                        loading="lazy"
                        onClick={() => setIndexTranslate(0)}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </Container>
        </div>
      )}
    </>
  );
};

export default Navigation;
