import { Link } from "react-router-dom";
import "./offer.css";
import offer1 from "./offer1.webp";
import offer2 from "./offer2.webp";
import { useState } from "react";
const Offer = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      {!show ? (
        <div
          className="offer"
          onClick={() => {
            setShow(!show);
          }}
        >
          <lord-icon
            src="https://cdn.lordicon.com/nkmsrxys.json"
            trigger="loop"
            colors="primary:#636363,secondary:#00992a"
            style={{ width: "50px", height: "50px" }}
          ></lord-icon>
        </div>
      ) : (
        <div
          className="offer"
          onClick={() => {
            setShow(!show);
          }}
        >
          <lord-icon
            src="https://cdn.lordicon.com/iiueiwdd.json"
            trigger="loop"
            colors="primary:#00992a,secondary:#00992a"
            stroke="100"
            style={{ width: "35px", height: "35px" }}
          ></lord-icon>
        </div>
      )}
      {show && (
        <div className="popup-image">
          <img src={offer2} alt="" />
          <img src={offer1} alt="" />
        </div>
      )}
    </>
  );
};

export default Offer;
