import audz1 from "../../../assets/images/Audz/Audaz1.webp";
import audz2 from "../../../assets/images/Audz/Audaz2.webp";
import audz3 from "../../../assets/images/Audz/Audaz3.webp";
import audz4 from "../../../assets/images/Audz/Audaz4.webp";
import audz5 from "../../../assets/images/Audz/Audaz5.webp";
import audz6 from "../../../assets/images/Audz/Audaz6.webp";

import Company from "../../../components/company/Company";
import { companyName, logos } from "../../../data/data";
import { useRecoilState } from "recoil";
import IndexTranslate from "../../../atoms/translateIndex";



export const audazImages=[audz1,audz2,audz3,audz4,audz5,audz6]
const Audaz = () => {
  const newImages =audazImages.map(image =>{
    return {
      original: image,
      thumbnail: image,
      originalHeight: "300px",
    }
  })
  const [indexTranslate] = useRecoilState(IndexTranslate);
  return (
    <div className="section-top">
      <Company
        companyName={companyName[1][indexTranslate]}
        companyLogo={logos[1]}
        images={newImages}
      />
    </div>
  );
};

export default Audaz;
