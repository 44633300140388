import { Container } from "react-bootstrap";
import "./privacy.css";
import { useRecoilState } from "recoil";
import TranslateAtom from "../../atoms/TranslateAtom";
import IndexTranslate from "../../atoms/translateIndex";
const Privacy = () => {
  const [translate] = useRecoilState(TranslateAtom);
  const [indexTranslate] = useRecoilState(IndexTranslate);
  const { title, des } = { ...translate.privacy };
  return (
    <>
      {indexTranslate === 0 ? (
        <div className="privacy-section">
          <Container>
            <h2 className="privacy-title ">{title[indexTranslate]}</h2>
            <p className="privacy-des">{des[indexTranslate]}</p>
          </Container>
        </div>
      ) : (
        <div className="privacy-section" style={{ direction: "rtl" }}>
          <Container>
            <h2 className="privacy-title ">{title[indexTranslate]}</h2>
            <p className="privacy-des">{des[indexTranslate]}</p>
          </Container>
        </div>
      )}
    </>
  );
};

export default Privacy;
